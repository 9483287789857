import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { reactLocalStorage } from 'reactjs-localstorage';

var isLoggedIn;

const token = reactLocalStorage.get('token',true)
if (token) {
  isLoggedIn = true;
} else {
  isLoggedIn = false;
}

ReactDOM.render(
  <React.StrictMode>
    <App isLoggedIn={isLoggedIn}/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
