import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function Navbar(props){

    var isLoggedIn = props.isLoggedIn;

    return (
        <div className="Navbar">
            <div className="left">
                <a className="logo">ArbiwReist</a>
                <Link to='/' className="menu">Home</Link>
                <Link to='/about' className="menu">About</Link>
                <Link to='/service' className="menu">Service</Link>
            </div>
                <Userinfo isLoggedIn={isLoggedIn} />
        </div>
    );
}

function Userinfo( props ){

    var isLoggedIn = props.isLoggedIn;

    if (isLoggedIn){
        return <a className="right">Sign in</a>
    } else {
        return
    }
}

export default Navbar;