import logo from './logo.svg';
import './Styles/App.css';
import Navbar from './Components/navbar'
import { reactLocalStorage } from 'reactjs-localstorage';
import { isElementOfType } from 'react-dom/cjs/react-dom-test-utils.production.min';

import Home from './Pages/home'
import About from './Pages/about'
import Service from './Pages/service'


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function App( props ) {

  var isLoggedIn = props.isLoggedIn;

  return (
    <div className="App">
      <Router>
        <Navbar isLoggedIn={isLoggedIn} />
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/service/:id" component={Service}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
