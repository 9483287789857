import './home.css'
import img from './images/abrimg.png'

import { useEffect, useState } from 'react'

import swal from 'sweetalert';

function Home() {
    return (
        <div className="home-container">
            <div className="donatebox">
                <h1>Donate me a coffee!</h1>
                <div className="box">
                    <div className="imgBx">
                        <img src={img} />
                    </div>
                    <div className="content">
                        <h2>ArbiwReist</h2>
                        <DonatePrice />
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
    );
}

function DonatePrice() {
    const [input, setInput] = useState('$ ');
    const [error, setError] = useState('');
    var inputError;
  
    function onChange(event) {
        if (!isNaN(event.nativeEvent.data) && event.nativeEvent.data != " ") {
            setInput(event.target.value);
            setError("")
        } else {
            setError("Please using only numbers.")
        }
    }

    function onSubmit(event){
        if (!isNaN(input.split(" ")[1]) && input.split(" ")[1] != ""){
            swal("Donate Success", "Thank you for your donation!", "success");
            event.preventDefault();
        } else {
            setError("Input is empty.")
            event.preventDefault();
        }
    }
  
    return (
        <form onSubmit={onSubmit} className="donation">
            <label>
                <div className="inputBox">
                    <input className="inputbox" type="text" value={input} onChange={onChange} />
                </div>
                <a>{error}</a>
            </label>
            <br />
            <input type="submit" value="Donate" />
        </form>
    );
  }

export default Home;